// Default element styling of everthing in the pic-mediabank container
.pic-mediabank {
    box-sizing: border-box;
    position: relative;
    font-family: $mediabank-font;
    font-weight: normal;
    font-style: normal;
    line-height: rem-calc(16);
    color: $mediabank-color-text;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    // Reset form because otherwise frameworks like foundation mess with it
    fieldset {
        margin: 0;
        padding: 0;
        border: none;
    }

    form {
        margin: 0;
        padding: 0;
        background: none;
    }

    a {
        text-decoration: none;
    }

}

.mediabank-container {
    max-width: $mediabank-container-width;
    margin: 0 auto;
    background: $mediabank-color-background;
}
