// Metadata
.mediabank-metadata-list {
    @include ul-reset();
    line-height: rem-calc(20);
    position:relative;

    .acs_keyword_help {
        position: absolute;
        left: 220px;
        margin-top: -3px;
        .icon-info-1 {
            display: block;
            width: 25px;
            height: 25px;
            line-height: 23px;
            text-align: center;
            -webkit-border-radius: 13px;
            -moz-border-radius: 13px;
            border-radius: 13px;
            color: $mediabank-color-text-light;
            background: $mediabank-color-contrast;
            &:before{
                font-family: 'mediabank-icons';
                content: '\e82f';
            }
        }
    }

    li {
        margin-bottom: rem-calc(4);
    }

    .metadata-heading {
        display: block;
        font-weight: 700;
    }

    .metadata-content {
        display: block;
    }
}

.metadata-content-hierarchy {
    padding-left: 15px;
    border-left: 2px solid $mediabank-color-light;

    ul {
        @include ul-reset();
        margin-bottom: 8px;

        li {
            margin-bottom: 0;
        }
    }

    .metadata-hierarchy-list {
        padding-left: 15px;
        border-left: 2px solid $mediabank-color-light;
    }
}

.metadata-hierarchy-heading {
    display: block;
    font-weight: 700;
}

.metadata-hierarchy-label {
    font-style: italic;

    &::after {
        content: ':';
        margin-right: 4px;
    }
}

.metadata-content-text {
    white-space: pre-line;
}
