// CSS only preloader, shows text Loading... in IE9

@keyframes spinner {
    to {transform: rotate(360deg);}
}
 
@-webkit-keyframes spinner {
    to {-webkit-transform: rotate(360deg);}
}

.mediabank-loader {
    position: absolute;
    display: none;
    min-width: 30px;
    min-height: 30px;
    
    &:before {
        content: 'Loading…';
        position: absolute;
        width: 30px;
        height: 30px;
    }
    
    &:not(:required):before {
        content: '';
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, .3);
        border-top-color: rgba(255, 255, 255, 1);
        animation: spinner .6s linear infinite;
        -webkit-animation: spinner .6s linear infinite;
    }
}
