// Assets
.mediabank-assets-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    cursor: pointer;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: top;
    width: 47%;

    &:nth-child(odd) {
      margin-right: 6%;
    }
  }

  .mediabank-asset-filename {
    display: block;
    margin: 2px 0;
    font-size: rem-calc(14);
    line-height: 20px;
    text-align: center;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .active {
    img {
      border: 5px solid $mediabank-color-contrast;
    }

    span {
      color: $mediabank-color-contrast;
      font-weight: bold;
    }
  }

}

.mediabank-detail-view-v2 .mediabank-detail-assets-bottom {
  width: 100%;
}

.mediabank-detail-assets-bottom {
  position: absolute;
  overflow: hidden;
  background-color: #dfdfdf;
  z-index: 10000;
  right: 0;
  bottom: 0;
  width: calc(100% - 560px);

  &.wide {
    width: calc(100% - 60px);
    height: 150px;
  }

  &__button {
    height: 100%;
    width: 40px;
    background-color: $mediabank-color-primary;
    vertical-align: middle;
    border: none;
    font-size: rem-calc(18px);
    position: absolute;
    cursor: pointer;
    &:hover {
      background-color: $mediabank-color-dark;
    }
    &[aria-disabled=true], [aria-disabled=true]:hover, &.slick-arrow.slick-hidden {
      display: block;
      background: lighten($mediabank-color-primary, 20%);
      cursor: default;
    }
    &.right {
      top: 0;
      right: 0;
    }
    i {
      color: #fff;
    }
  }

  &-img-container {
    cursor: pointer;
  }
}

.active .mediabank-detail-assets-bottom-img-container {
  border: 2px solid $mediabank-color-contrast;
  box-sizing: border-box;
}

#assets-on-bottom {
  height: 100%;
  max-height: 155px;
  margin: 0 40px;
}
