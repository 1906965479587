.pdf-viewer {
  width: 100%;
  height: 100%;
  position: relative;

  ng-pdf {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  #viewer .page {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }

  &__pagination {
    position: fixed;
    z-index: 2;
    bottom: 16px;
    right: 16px;
    //transform: translateX(-50%);
    padding: rem-calc(5);
    background: rgba(0, 0, 0, 0.5);
    text-align: left;
    color: #fff;
    white-space: nowrap;

    .pdf-pagination-jump {
        display: inline-block;
        margin: 0 10px;
        padding: rem-calc(5);
        width: 50px;
        height: 30px;
        border: none;
        border-radius: $mediabank-border-radius;
        background: #fff;
        font: inherit;
        font-size: rem-calc(16);
    }

    span {
      color: #0a0a0a;
    }

    .pdf-pagination-button {
        @include mediabank-button($mediabank-color-primary);
        margin: 0;
        padding: 5px 10px;

        &.button--next {
          margin-left: 10px;
        }
    }

  }

  &__button {
    @include button-reset();
    position: relative;
    display: block;
    margin-bottom: 2px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    color: #fff;
    cursor: pointer;
    outline: none;
    text-align: center;
    line-height: 40px;

    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }

    svg {
      @include center();
    }

    &--fit {
      font-size: 12px;
    }
    &--search {
      background-color:$mediabank-color-contrast;
      margin-bottom:12px;
      svg {
        width:26px;
        height:28px;
      }
    }
  }

  &__zoom {
    //position: fixed;
    z-index: 2;
    //right: 16px;
    //top: 88px;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__searchbox {
    position: absolute;
    right:46px;
    top:0;
    padding:24px;
    background-color:#ddd;
    width:292px;
    height:auto;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.23);
    text-align:left;
    h4 {
      margin:0 0 6px;
    }
    input {
      background: #fff;
      border: 1px solid #979797;
      width:100%;
      height:32px;
      padding:3px 33px 3px 6px;
      font-size:16px;
    }
    form {
      position:relative;
      margin-bottom:8px;
      button {
        position: absolute;
        right:1px;
        top:1px;
        width:32px;
        height:32px;
        border:none;
        background-color:transparent;
      }
    }
    .prev, .next {
      width:18px;
      height:18px;
      background-color:#787878;
      border:none;
      padding:2px;
      text-align:center;
      cursor:pointer;
      svg {
        width:14px;
        height:14px;
        fill: #fff;
      }
      &:hover {
        background-color: #ccc;
      }
    }
  }
}
.textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0;

  >span {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }

  .highlight {
    margin: -1px;
    padding: 1px;
    background-color: $mediabank-pfdviewer-highlight;
    border-radius: 4px;

    &.begin {
      border-radius: 4px 0px 0px 4px;
    }

    &.end {
      border-radius: 0px 4px 4px 0px;
    }

    &.middle {
      border-radius: 0px;
    }

    &.selected {
      background-color: $mediabank-pfdviewer-highlight-active;
    }
  }

  ::-moz-selection,
  ::selection {
    background: rgb(0, 0, 255);
  }

  .endOfContent {
    display: block;
    position: absolute;
    left: 0px;
    top: 100%;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.active {
      top: 0px;
    }
  }
}
