@font-face {
  font-family: 'mediabank-icons';
  src: url('../font/mediabank-icons.eot?37714885');
  src: url('../font/mediabank-icons.eot?37714885#iefix') format('embedded-opentype'),
  url('../font/mediabank-icons.woff2?37714885') format('woff2'),
  url('../font/mediabank-icons.woff?37714885') format('woff'),
  url('../font/mediabank-icons.ttf?37714885') format('truetype'),
  url('../font/mediabank-icons.svg?37714885#mediabank-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'mediabank-icons';
    src: url('../font/mediabank-icons.svg?37714885#mediabank-icons') format('svg');
  }
}
*/
[class^="pic-icon-"]:before, [class*=" pic-icon-"]:before {
  font-family: "mediabank-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.pic-icon-print:before { content: '\e800'; } /* '' */
.pic-icon-female:before { content: '\e801'; } /* '' */
.pic-icon-male:before { content: '\e802'; } /* '' */
.pic-icon-child:before { content: '\e803'; } /* '' */
.pic-icon-heart:before { content: '\e804'; } /* '' */
.pic-icon-eye:before { content: '\e805'; } /* '' */
.pic-icon-help:before { content: '\e806'; } /* '' */
.pic-icon-comment:before { content: '\e807'; } /* '' */
.pic-icon-plus:before { content: '\e808'; } /* '' */
.pic-icon-cloud-sun:before { content: '\e809'; } /* '' */
.pic-icon-search:before { content: '\e80a'; } /* '' */
.pic-icon-filter:before { content: '\e80b'; } /* '' */
.pic-icon-location:before { content: '\e80c'; } /* '' */
.pic-icon-map:before { content: '\e80d'; } /* '' */
.pic-icon-camera:before { content: '\e80e'; } /* '' */
.pic-icon-menu:before { content: '\e80f'; } /* '' */
.pic-icon-clipboard:before { content: '\e810'; } /* '' */
.pic-icon-book-open:before { content: '\e811'; } /* '' */
.pic-icon-male-female:before { content: '\e812'; } /* '' */
.pic-icon-down-open-mini:before { content: '\e813'; } /* '' */
.pic-icon-left-open-mini:before { content: '\e814'; } /* '' */
.pic-icon-right-open-mini:before { content: '\e815'; } /* '' */
.pic-icon-up-open-mini:before { content: '\e816'; } /* '' */
.pic-icon-link:before { content: '\e817'; } /* '' */
.pic-icon-key:before { content: '\e818'; } /* '' */
.pic-icon-edit:before { content: '\e819'; } /* '' */
.pic-icon-basket-alt:before { content: '\e81a'; } /* '' */
.pic-icon-scan-register:before { content: '\e81b'; } /* '' */
.pic-icon-female-1:before { content: '\e81c'; } /* '' */
.pic-icon-male-1:before { content: '\e81d'; } /* '' */
.pic-icon-error:before { content: '\e81e'; } /* '' */
.pic-icon-plus-circle-1:before { content: '\e81f'; } /* '' */
.pic-icon-search-circle:before { content: '\e820'; } /* '' */
.pic-icon-children:before { content: '\e821'; } /* '' */
.pic-icon-twitter:before { content: '\e822'; } /* '' */
.pic-icon-facebook:before { content: '\e823'; } /* '' */
.pic-icon-gplus:before { content: '\e824'; } /* '' */
.pic-icon-pinterest:before { content: '\e825'; } /* '' */
.pic-icon-linkedin:before { content: '\e826'; } /* '' */
.pic-icon-mail:before { content: '\e827'; } /* '' */
.pic-icon-link-ext:before { content: '\e828'; } /* '' */
.pic-icon-gear:before { content: '\e829'; } /* '' */
.pic-icon-fluid:before { content: '\e82a'; } /* '' */
.pic-icon-gallery:before { content: '\e82b'; } /* '' */
.pic-icon-list:before { content: '\e82c'; } /* '' */
.pic-icon-geo:before { content: '\e82d'; } /* '' */
.pic-icon-timeline:before { content: '\e82e'; } /* '' */
.pic-icon-info:before { content: '\e82f'; } /* '' */
.pic-icon-close:before { content: '\e830'; } /* '' */
.pic-icon-table:before { content: '\e831'; } /* '' */
.pic-icon-arrow-down:before { content: '\e832'; } /* '' */
.pic-icon-arrow-left:before { content: '\e833'; } /* '' */
.pic-icon-arrow-right:before { content: '\e834'; } /* '' */
.pic-icon-arrow-up:before { content: '\e835'; } /* '' */
.pic-icon-linkeddata:before { content: '\e836'; } /* '' */
.pic-icon-vertical:before { content: '\e837'; } /* '' */
.pic-icon-download:before { content: '\e838'; } /* '' */
.pic-icon-assets:before { content: '\e839'; } /* '' */
.pic-icon-instagram:before { content: '\e840'; } /* '' */
.pic-icon-x-logo:before { content: '\e841'; } /* '' */
.pic-icon-iiif-logo:before { content: '\e842'; } /* '' */

// Same icons different class names
.pic-icon-scenes:before { content: '\e839'; } /* '' */
//This is a placeholder icon for demos
.pic-icon-placeholder:before { content: '\e836'; } /* '' */

// Sort icons
.pic-icon-sort-desc:before { content: '\e832'; } /* '' */
.pic-icon-sort-asc:before { content: '\e835'; } /* '' */

.pic-icon-comments:before { content: '\e807'; } /* '' */
.pic-icon-metadata:before { content: '\e82f'; } /* '' */
.pic-icon-permalink:before { content: '\e817'; } /* '' */
.pic-icon-webshop:before { content: '\e81a'; } /* '' */
.pic-icon-horizontal:before { content: '\e82a'; } /* '' */
.pic-icon-twitter:before { content: '\e841'; }
.pic-icon-iiif:before { content: '\e842'; }