.mediabank-topviewer.image {
  background: $mediabank-color-gray-dark;
}

.openseadragon-container .navigator {
  top: 10px;
  right: 60px;
  border-width: 1px !important;
}

.topviewer-toolbar {
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 10px;
  transition: transform 0.2s ease-out;

  @include breakpoint(small) {
    transform: none !important;
  }

  &--hidden {
    transform: translateX(70px);
  }

  &__button {
    @include button-reset();
    position: relative;
    display: block !important;
    // margin-bottom: 2px;
    width: 44px;
    height: 44px;
    cursor: pointer;
    background: rgba(20, 20, 20, 0.6);
    transition: background 0.25s ease-out;
    outline: none;

    svg {
      @include center();
      opacity: 0.8;
    }

    &:hover {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.5);
      background: rgba(20, 20, 20, 0.8);
      transition: background 0.2s ease-out;

      svg {
        opacity: 1;
        filter: drop-shadow(0 1px 1px #000);
      }
    }

    &:first-child:hover {
      box-shadow: none;
    }

    &:disabled {
      cursor: default;
      opacity: 0.4 !important;
    }
  }
}

// Hide most of the toolbar buttons on tiny screens
#openseadragon-button-zoomin,
#openseadragon-button-zoomout,
#openseadragon-button-navigator,
#openseadragon-button-left,
#openseadragon-button-right {
  @include breakpoint(mobile) {
    display: none !important;
  }
}

#openseadragon-watermark {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-repeat: repeat;
  background-color: transparent;
  background-position: 0 0;
  opacity: 0.3;
}

#osd-imagetools {
  top: 20px !important;
  left: 20px !important;
  z-index: 1 !important;
  padding: 20px !important;
  button {
    margin-top: 10px !important;
  }
}

.no-image #OpenSeadragon .openseadragon-container {
  display: none;
}
