.mediabank-result-header {
    @include clearfix();
    margin-bottom: 20px;
    padding: 10px 20px;
    background: $mediabank-color-light;

    .mediabank-results-total {
        float: left;
        line-height: 30px;

        @include breakpoint(mobile) {
            display: none;
            float: none;
        }
    }

    .mediabank-pagination {
        float: right;
    }
}

.mediabank-pagination {
    .pagination-form {
        display: inline-block;
    }

    .pagination-jump {
        margin: 0 10px;
        padding: rem-calc(5);
        width: 40px;
        height: 30px;
        border: none;
        border-radius: $mediabank-border-radius;
        background: #fff;
        font: inherit;
        font-size: rem-calc(16);
    }

    .pagination-button-prev,
    .pagination-button-next {
        @include mediabank-button($mediabank-color-primary);
        margin-left: 6px;
        padding: 5px 10px;
    }
}

// Back button
.mediabank-button-back {
    @include mediabank-button($mediabank-color-primary);
    position: absolute;
    z-index: 100;
    left: 20px;
    top: 20px;
    padding-left: 10px;

    @include breakpoint(small) {
        padding: 10px;

        span {
            display: none;
        }
    }
}

.mediabank-detail-paginator {
    position: absolute;
    top: 20px;
    right: 20px;

    @include breakpoint(mobile) {
        display: none;
    }

    .pagination-button-prev,
    .pagination-button-next {
        display: inline-block;
        padding: 10px;
    }
}
