.mediabank-search {
    position: relative;
    margin-bottom: 20px;
    padding: 20px;
    min-height: 80px;
    background: $mediabank-color-light;

    .mediabank-search-form {
        position: relative;
    }

    .mediabank-search-fieldset {
        position: relative;
        margin: 0 auto;
        padding-right: 100px;
        width: 50%;

        @include breakpoint(medium) {
            width: 60%;
        }

        @include breakpoint(small) {
            width: 100%;
            padding-right: 140px;
        }

        @include breakpoint(mobile) {
            padding-right: 0;
        }
    }

    .mediabank-search-term {
        padding: rem-calc(5 10);
        width: 100%;
        height: 40px;
        border: none;
        font-size: rem-calc(16);
        color: $mediabank-color-text;
    }

    .mediabank-search-submit {
        @include mediabank-button();
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;

        @include breakpoint(small) {
            width: 140px;
        }

        @include breakpoint(mobile) {
            right: 0;
            width: 40px;
            padding: 10px 0;

            span {
                display: none;
            }
        }

        i {
            display: none;
            font-size: rem-calc(20);

            @include breakpoint(mobile) {
                display: block;
            }
        }
    }

    .mediabank-search-help {
        @include mediabank-button($mediabank-color-primary);
        display: block;
        position: absolute;
        right: 100px;
        top: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: rem-calc(18);
        line-height: 40px;

        @include breakpoint(mobile) {
            display: none;
        }
    }

    // Autocomplete dropdown
    .angucomplete-holder {
        position: relative;
    }

    .angucomplete-dropdown {
        position: absolute;
        z-index: 11000;
        width: 100%;
        // padding-bottom: 10px;
        background: #fff;
        border: 1px solid #ececec;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }

    .angucomplete-searching {
        padding: 10px;
        color: $mediabank-color-text;
        font-size: rem-calc(14);
    }

    .angucomplete-description {
        font-size: rem-calc(14);
    }

    .angucomplete-row {
        @include clearfix();
        padding: 5px 10px;
        border-bottom: 1px solid #dedede;
        font-size: rem-calc(14);
        color: $mediabank-color-text;
        vertical-align: middle;

        img {
            float: left;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .angucomplete-selected-row {
        background-color: tint($mediabank-color-contrast, 95%);
        color: $mediabank-color-text;
    }

    .angucomplete-image-holder {
        float: left;
        max-width: 60px;
        max-height: 40px;
        margin-right: 10px;
        overflow: hidden;
    }

    .angucomplete-image {
        margin: 2px 0;
        width: 60px;
    }

    // On detail page
    &.mediabank-detail {
        @include breakpoint(small) {
            padding-left: 80px;
            padding-right: 130px;

            .mediabank-search-fieldset {
                padding-right: 40px;
            }

            .mediabank-search-help {
                display: none;
            }

            .mediabank-search-submit {
                width: 40px;
                padding: 10px 0;

                span {
                    display: none;
                }

                i {
                    display: block;
                }
            }
        }

        @include breakpoint(mobile) {
            padding-left: 80px;
            padding-right: 20px;

            .mediabank-search-fieldset {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}
