.mediabank-gallery-container {
    position: relative;
}

.pic-load-previous-container {
    text-align: center;
    padding-bottom: 15px;

    .pic-load-previous {
        border: none;
        margin: 0 auto;
        padding: 15px;
        transition: background 0.2s ease-out;
        cursor: pointer;
        background: $mediabank-color-primary;
        color: #fff;
        font-size: rem-calc(16);

        &:hover {
            background: shade($mediabank-color-primary, 10%);
        }
    }
}
.tooltip-toggle {
    display: none;
    .tooltip-toggle-btn {
        @include mediabank-button();
        margin-left: 10px;
    }
}

.mediabank-container .mediabank-gallery-container .mediabank-gallery-image .caption {
    display: none !important;
}

.mediabank-gallery {
    $horizontal-spacing: 30px;
    $vertical-spacing: 30px;
    backface-visibility: hidden;
    //perspective: 1000;
    overflow: hidden;
    margin-top: $horizontal-spacing;
    padding: 0 20px;

    .mediabank-result-default {
        margin: 0 (-$horizontal-spacing / 2);
    }

    // List item containing the thumb
    .mediabank-gallery-item {
        display: inline-block;
        position: relative;
        margin-bottom: $vertical-spacing;
        padding: 0 ($horizontal-spacing / 2);
        width: (100% / 4);
        vertical-align: top;

        @include breakpoint(medium) {
            width: (100% / 3);
        }

        @include breakpoint(small) {
            width: (100% / 2);
        }

        @include breakpoint(mobile) {
            width: 100%;
        }

        &.focused {
            margin-bottom: 640px;

            // Triangle marking this as the active image
            &::after {
                @include triangle('up', 20px, $mediabank-color-gray-dark);
                position: absolute;
                bottom: -20px;
                left: 50%;
                margin-left: -20px;
                content: '';
                display: block;
            }
        }

    }

    .mediabank-gallery-view-image {
        background: $mediabank-color-light;

        &__multiple {
            display: block;
            width: 45px;
            height: 36px;
            position: absolute;
            top: 6px;
            right: 21px;
            cursor: default;

            svg {
                margin: 6px 0 0 6px;

            }
        }
    }

    .mediabank-gallery-thumb {
        position: relative;
        display: block;
        overflow: hidden;

        .mediabank-thumb-container {
            position: relative;
        }

        .spinner-wrapper {
            height: 100%;
            background: $mediabank-color-light;
            opacity: 0.7;
            height: 20vw !important;

            @include breakpoint(medium) {
                height: (80vw / 3) !important;
            }

            @include breakpoint(small) {
                height: (80vw / 2) !important;
            }

            @include breakpoint(mobile) {
                height: 80vw !important;
            }
        }

        img {
            transform: scale(1.08, 1.08) translate3d(0, 0, 0);
            transition: all 0.25s ease-out;
            width: 100%;
            height: auto;
            vertical-align: top;
            border: none;
        }

        &:hover {
            img {
                transform: scale(1, 1);
                transition: all 0.15s ease-out;
            }
        }

    }

    .mediabank-page-prev {
        left: ($horizontal-spacing / 2);
    }

    .mediabank-page-next {
        right: ($horizontal-spacing / 2);
    }
}

.mediabank-thumb-pagination {
    position: absolute;
    top: 85px;
    left: 0;
    right: 0;

    // pagination button
    a {
        position: absolute;
        top: 0;
        display: block;
        width: 30px;
        height: 30px;
        color: #fff;
        background: $mediabank-color-primary;
        line-height: 30px;
        text-align: center;
        transition: background 0.2s ease-out;
        text-decoration: none;

        &:hover {
            color: #fff;
            background: shade($mediabank-color-primary, 10%);
        }

        &.disabled {
            cursor: default;

            i {
                opacity: 0.3;
            }

            &:hover {
                color: #fff;
                background: $mediabank-color-primary;
            }
        }
    }
}

// Detail focus shown when user clicks on thumb in gallery
.mediabank-gallery-focused {
    @include clearfix();
    position: absolute;
    margin-top: rem-calc(20);
    padding: rem-calc(40 20);
    height: 600px;

    .table-focused & {
      position: static;
      height: auto;
    }

    background: $mediabank-color-gray-dark;

    .mediabank-focused-left {
        float: left;
        width: 65%;
        height: 100%;
        padding: rem-calc(0 10);
    }

    .mediabank-focused-right {
        position: relative;
        float: right;
        width: 35%;
        padding: rem-calc(0 10);
    }

    .mediabank-gallery-focused-thumb {
        position: relative;
        height: 100%;
        background: shade($mediabank-color-gray-dark, 30%);

        .table-focused & {
          height: 520px;
        }

        img {
            @include center();
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
        }
    }

    .mediabank-gallery-focused-info {
        margin-bottom: rem-calc(20);
        color: $mediabank-color-text-light;
        max-height: 400px;
        .table-focused & {
          max-height: none;
        }
        overflow-y: auto;

        h3 {
            margin: rem-calc(0 0 20);
            padding-right: 30px;
            color: $mediabank-color-text-light;
        }
    }

    .mediabank-metadata-list {
        @include ul-reset();
        line-height: rem-calc(20);

        li {
            margin-bottom: rem-calc(4);
        }

        .metadata-heading {
            display: block;
            font-weight: 700;
        }

        .metadata-content {
            display: block;
        }
    }

    .mediabank-detail-button {
        @include mediabank-button($mediabank-color-dark);
        display: inline-block;

    }

    .mediabank-focused-close {
        @include mediabank-close-button();
        position: absolute;
        z-index: 13;
        right: 20px;
        top: 20px;

        label {
            display: none;
        }
    }

    @include breakpoint(small) {
        padding: rem-calc(20 10);

        .mediabank-focused-left {
            width: 100%;
        }

        .mediabank-focused-right {
            display: none;
            width: 100%;
            margin-top: 20px;
        }

        .mediabank-focused-close {
            right: 10px;
            top: 10px;
        }
    }
}

// Fluid resultmode
.mediabank-fluid {
    .-image,
    .masonry-brick {
        cursor: pointer;
    }

    .title-block-container {
        h1, h2 {
            font-weight: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h1 {
            color: $mediabank-color-text;
            margin-bottom: 5px;
            font-size: rem-calc(15);
        }

        h2 {
            color: $mediabank-color-text-subtitle;
            font-size: rem-calc(14.5);
            margin-top: 0;
            font-style: italic;
        }
    }

    .mediabank-gallery-horizontal-image {
        background: $mediabank-color-light;
        &__multiple {
            background: $mediabank-color-dark;
            display: block;
            width: 45px;
            height: 36px;
            position: absolute;
            top: 6px;
            right: 6px;
            cursor: default;
            svg {
                margin:6px 0 0 6px;

            }
        }
    }

    .masonry-grid-sizer,
    .masonry-brick {
        width: 19%;

        img {
            max-width: none;
        }
    }

    .masonry-gutter-sizer {
        width: 1%;
    }

    .masonry-brick {
        margin-bottom: 10px;
    }

    .mediabank-page-prev {
        left: 0;
    }

    .mediabank-page-next {
        right: 0;
    }

    .justified-gallery>a, .justified-gallery>div {
        opacity: 0.7;
    }

    @include breakpoint(medium) {
        .masonry-grid-sizer,
        .masonry-brick {
            width: 24%;
        }

        .masonry-gutter-sizer {
            width: 1%;
        }
    }

    @include breakpoint(small) {
        .masonry-grid-sizer,
        .masonry-brick {
            width: 31%;
        }

        .masonry-gutter-sizer {
            width: 2%;
        }
    }

    @include breakpoint(mobile) {
        .masonry-grid-sizer,
        .masonry-brick {
            width: 48%;
        }

        .masonry-gutter-sizer {
            width: 2%;
        }
    }
}

.spinner-wrapper {

    .mediabank-gallery &,
    .mediabank-gallery-horizontal & {
        width: 100% !important;
        height: 100% !important;
    }

    .mediabank-gallery-vertical & {
        background: $mediabank-color-light;
    }

    double-bounce-spinner {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }

    .double-bounce-spinner {
        @include center();
        margin: 0;
    }

    .double-bounce1,
    .double-bounce2 {
        background: $mediabank-color-primary;
    }
}
