.mediabank-gallery-tooltip {
    @include clearfix();
    position: fixed;
    z-index: 999999;

    .tooltip-content {
        position: absolute;
        bottom: 50px;
        left: -175px; // Center tooltip
        width: 350px;
        padding: 15px;
        background: #fff;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
        border: 2px solid $mediabank-color-primary;

        &::after {
            @include triangle('down', 20px, $mediabank-color-primary);
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            margin-left: -20px;
            bottom: -40px;
        }
    }
}
