/* The Modal (background) */
.mediabank-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10010; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  /* Modal Content */
  .mediabank-modal-popup {
    background-color: #fefefe;
    position:relative;
    height: calc(100vh - 200px);
    min-height:300px;
    margin: auto;
    padding: 25px 0;
    border: 1px solid #888;
    width: 80%;
    .mediabank-modal-content {
      overflow:auto;
      height:100%;
      margin:0 25px;
    }

    /* The Close Button */
    .close {
      position:absolute;
      color: #aaaaaa;
      right:10px;
      top:10px;
      font-size: 40px;
      line-height:25px;
      font-weight: bold;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
}