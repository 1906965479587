.mediabank-gallery-list {
    margin-top: rem-calc(30);
    padding: rem-calc(0 20);

    .mediabank-result-list {
        @include ul-reset();

        >li {
            @include clearfix();
            padding-bottom: rem-calc(20);
            margin-bottom: rem-calc(30);
            border-bottom: 1px solid tint($mediabank-color-primary, 40%);
        }

        .mediabank-gallery-list-icon {
            background: $mediabank-color-light;

            &__multiple {
                display: block;
                width: 45px;
                height: 36px;
                position: absolute;
                top: 6px;
                right: 6px;
                cursor: default;

                svg {
                    margin: 6px 0 0 6px;

                }
            }
        }
    }

    .mediabank-list-thumb {
        width: 25%;
        float: left;
    }

    .mediabank-list-info {
        width: 75%;
        float: right;
        padding-left: rem-calc(30);
    }

    .mediabank-gallery-thumb {
        display: block;
        position: relative;
        // max-height: 400px;
        background: $mediabank-color-light;

        >a {
            display: block;
        }

        img {
            border: none;
            width: 100%;
            height: auto;
        }
    }

    // thumb pagination buttons
    .mediabank-page-next {
        right: 0;
    }

    .mediabank-page-prev {
        left: 0;
    }
}
