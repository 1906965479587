.mediabank-error-view {
    position: relative;
    margin: 20px 0;
    padding: 0 20px;
}

.mediabank-error-container {
    position: relative;
    min-height: 80px;
    padding: 20px;
    background: tint($mediabank-color-error, 80%);
    border: 2px solid $mediabank-color-error;

    p {
        margin: 0 40px;
        color: $mediabank-color-text;
        font-size: 18px;
        text-align: center;
    }

    .mediabank-error-close {
        @include mediabank-close-button();
        position: absolute;
        top: 15px;
        right: 15px;
        background: $mediabank-color-error;
        color: #fff;

        &:hover {
            background: shade($mediabank-color-error, 10%);
            color: #fff;
        }
    }
}
