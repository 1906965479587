// Section containing sort & gallery mode buttons
.mediabank-result-options {
    @include clearfix();
    position: relative;
    margin: 30px 0 20px;
    border-bottom: 1px solid tint($mediabank-color-primary, 40%);

    // There should be only one resultmode on mobile
    @include breakpoint(mobile) {
        .mediabank-gallery-sort {
            display:none;
        }
        .mediabank-gallery-mode {
            &-vertical, &-item-list {
                display: none;
            }
        }
        .mediabank-mode-item {
            &-vertical, &-table {
                display: none;
            }
        }
    }
}

// Sort dropdown
.mediabank-sort-label {
    display: inline-block;
    margin-right: rem-calc(10);
    position: relative;
    vertical-align: sub;
}

.mediabank-sort-select {
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.mediabank-gallery-sort {
    float: left;
    position: relative;
    margin-left: 20px;

    ul {
        @include ul-reset();
        position: absolute;
        top: 0;
        z-index: 2;
        line-height: rem-calc(16);
    }

    li {
        display: none;
        position: relative;
        transition: background 0.2s ease-out;
        padding: 5px 40px 5px 10px;
        border-bottom: 1px solid #fff;
        cursor: pointer;
        background: $mediabank-color-primary;
        color: #fff;

        &.active {
            display: block;
        }

        &:first-child {
            padding: 5px 10px 5px 10px;
        }
        
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background: shade($mediabank-color-primary, 10%);
        }

        // icon
        i {
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 12px;
        }
    }

    .open {
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);

        li {
            display: block;
        }
    }
}

// Gallery mode buttons
.mediabank-gallery-mode {
    float: right;
    margin-right: 20px;
    height: 32px;

    ul {
        @include ul-reset();
        @include clearfix();
        line-height: rem-calc(16);
    }

    li {
        float: left;
        margin: 0 4px;
        background: #fff;

        &:last-child.active {
            margin-right: 0;
        }
    }

    .active {
        margin-top: -10px;
        padding: 10px;
        border: 1px solid tint($mediabank-color-primary, 40%);
        border-bottom: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        a {
            color: $mediabank-color-contrast;
        }
    }

    span {
        @include visuallyhidden();
    }

    [class^="pic-icon-"]:before {
        // margin: 0;
        font-size: 22px;
    }

    a {
        color: $mediabank-color-primary;
        transition: color 0.2s ease-out;
        text-decoration: none;

        &:hover {
            color: $mediabank-color-contrast;
            transition: color 0.1s ease-out;
        }
    }
}

// List containing search results
.mediabank-result-default {
    @include ul-reset();
    @include clearfix();
}
