// Colors
$mediabank-color-light: #ebe7df;
$mediabank-color-primary: #b7ae9d;
$mediabank-color-dark: #989286;
$mediabank-color-contrast: #d50642; //red-color
$mediabank-color-gray-dark: #535353;
$mediabank-color-gray-mid: #727272;
$mediabank-color-topviewer: #535353;
$mediabank-color-background: #fff;
$mediabank-color-error: #c92236;
$mediabank-color-success: #3adb76;

// Typography
$mediabank-font: 'Helvetica Neue', Helvetica, Arial, sans-serif; //inherit;
$mediabank-color-text: #333; //inherit;
$mediabank-color-text-light: #eee; //inherit;
$mediabank-color-text-subtitle: #777;

$mediabank-border-radius: 0;

// Layout
$mediabank-container-width: 1440px;

// PDF viewer
$mediabank-pfdviewer-highlight:$mediabank-color-contrast;
$mediabank-pfdviewer-highlight-active:$mediabank-color-success;
