// Default map styling
.mediabank-map {
    // Only contains one option now, might as well hide it...
    .leaflet-control-layers {
        visibility: hidden;
    }
}

// Result map
.mediabank-gallery-map {
    position:relative;
    z-index:1;
    .mediabank-map {
        width: 100%;
        height: 640px;
    }

    .mediabank-loader {
        z-index:990;
        position: absolute;
        top: 10px;
        right:0px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        transform: translateX(-50%);
        &:before {
            border:2px solid rgba(0,0,0,0.3);
            border-top-color:#000000;
        }
    }

    &.loading {
        .mediabank-loader {
            display: block;
        }
    }
}

// Detail map
.mediabank-detail-map {
    margin-bottom: 20px;

    .mediabank-map {
        width: 100%;
        height: 200px;
    }
}
.marker-cluster-extra-large {
    background-color: rgba(153, 212, 232, 0.6);
}
.marker-cluster-extra-large div {
    background-color: rgba(83, 185, 219, 0.6);
}

