#qrcode {
  width:160px;
  height:160px;
  margin-top:15px;
}

.qrcode-container {
  margin-top: 50px;
  img {
	width: 150px;
	height: 150px;
  }
}
