/* Slider */
.mediabank-detail-assets-bottom {
    .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }
    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }

        .slick-loading & {
            visibility: hidden;
        }
    }
}

.slick-initialized .slick-slide {
    display: flex !important;
}

.slick-slide {
    float: left;
    height: 155px;
    min-height: 1px;
    align-items: center;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none
    }
    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-track img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.mediabank-detail-view-v2 {

    .slick-arrow i::before {
        color: #333;
    }

    .mediabank-detail-assets-bottom {
        background-color: #fff;
        border-bottom: 1px solid #535353;;

        .mediabank-detail-assets-bottom__button {
            background: #fff;
        }

    }


}
