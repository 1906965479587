// Variables used for slider filter
$slider-marker-color: #fff;
$slider-label-color: #fff;
$slider-selection-color: $mediabank-color-contrast;
$slider-pointer-color: #fff;

.slider-container {
  margin: 36px 0 0;
}

slider .bar.full,
slider .bar.unselected {
  background: none;
  border: none;
}

$height: 10px;
slider, [slider] {
  height: $height;
  display: inline-block;
  position: relative;
  height: 22px;
  width: 100%;
  margin: 10px 0px 10px;
  vertical-align: middle;

  div {
    white-space: nowrap;
    position: absolute;

    &.bar {
      width: 100%;
      height: $height;
      -webkit-border-radius: 1em/1em;
      border-radius: 1em/1em;
      border: none;
      background: rgba(255, 255, 255, 0.4);
      overflow: hidden;
      z-index: 0;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.7);

      .selection {
        width: 0%;
        z-index: 1;
        background: $slider-selection-color;
        height: 10px;
        border: none;
        border-radius: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
      }
    }

    &.handle {
      box-shadow: 0 2px 1px rgba(0,0,0,0.3);
      cursor: pointer;
      width: 20px;
      height: 20px;
      top: -5px;
      background-color: $slider-pointer-color;
      z-index: 2;
      -webkit-border-radius: 1em/1em;
      border-radius: 1em/1em;

      &:after {
        content: '';
        background-color: #333;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 7px;
        left: 7px;
        -webkit-border-radius: 1em/1em;
        border-radius: 1em/1em;

        &:hover {
          background-color: $mediabank-color-contrast;
        }
      }

      &.active:after {
        background-color: $mediabank-color-contrast;
      }
    }

    &.bubble {
      cursor: default;
      top: 20px;
      padding: 1px 3px 1px 3px;
      font-size: 0.8em;
      font-family: sans-serif;
      display: none !important;
      &.active {
        display: inline-block;
      }

      &.limit {
        display: none !important;
      }
    }
  }
}


.timeline-container {
  margin: 0px 10px;
}

.slider-container table.period-chart {
    margin: 0;
    width: 100%;
    border: none;
    background-color: transparent;
    border-collapse: collapse;

    td, td {
        padding: 0px;
        margin: 0px;
        background-color: transparent;
    }

    .markers {
        td {
            height: 8px;
            border-bottom: 1px solid $slider-marker-color;
            border-left: 1px solid $slider-marker-color;
            border-right: 1px solid $slider-marker-color;

            .marker {
                height: 6px;
                min-height: 6px;
                max-height: 6px;
                line-height: 6px;
            }
        }
    }

    .labels {
        td {
            position: relative;

            .wrapper {
                position: relative;
            }

            .label {
                font-size: 11px;
                background: transparent;
                padding: 0px;
                margin: 0px;
                text-align: center;
                text-shadow: none;
                color: $slider-label-color;
                position: relative;
                left: -50%;
                display: block;

                span {
                    width: 24px;
                    max-width: 24px;
                    min-width: 24px;
                    display: inline-block;
                }
            }

            .label.last {
                position: absolute;
                top: 0px;
                right: -150%;
            }
        }
    }

    // Set background of rows to prevent foundation from adding a bg
    tr:nth-of-type(even),
    tr:nth-of-type(even) {
        background: none;
    }
}

.pic-period-box {
  text-align: center;
  color: #fff;

  input {
    border: none;
    display: inline-block !important;
    font-size: 1rem;
    height: 24px;
    padding: 0 6px !important;
    width: auto !important;
  }
}
