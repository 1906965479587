.mediabank-container.mediabank-mode-detail {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none;
    overflow-x: hidden;
}

.pic-mediabank-detail-view .mediabank-comment-overview h3 {
  margin-top: 15px;
}

.vertical-view {
    overflow-y: auto;
    height: 100%;
}

.mediabank-detail-print {
    display: none;
}

.mediabank-detail-view-v2 {
    margin-top: -20px;
    overflow-x: hidden;
    position: relative;
    #pdf-viewer__container {
        overflow: auto;
        height: calc(100vh - 241px);
        @include breakpoint(small) {
            height: calc(100vh - 245px);
        }
    }
    .pdf-viewer__zoom {
        right:20px;
    }
    .pdf-viewer__pagination {
        bottom: 20px;
        background-color:rgba(255,255,255,0.8);
        position:absolute;
    }
    .mediabank-topviewer-v2 {
        background: #535353;
        position: relative;
        padding: 15px;
        text-align: center;
        height: calc(100vh - 211px);
        @include breakpoint(small) {
            height: calc(100vh - 215px);
            padding: 15px 15px 15px 10px;
        }
        .openseadragon-container {
            background: $mediabank-color-topviewer;
        }
        .topviewer-toolbar {
            top: 40px;
            @include breakpoint(small) {
                top: 4.5%;
                right: 5px;
            }
        }
    }
    .audio {
        height: 325px;
        .videogular-container {
          left: 0;
        }
        .audio-image {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            max-height:325px;
            left: 0;
            right: 0;
            top: -10px;
        }
    }
    .video {
        padding: 0;
    }
    .popup-model {
        display: block;
        position: fixed;
        z-index: 99;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
        .mediabank-model-container {
            background-color: #fefefe;
            padding: 20px;
            width: 60%;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            top: 30%;
            @include breakpoint(small) {
              width: 90%;
            }
            @include breakpoint(medium) {
              width: 80%;
            }
            .mediabank-permalink-copy {
                right: 0px;
                width:10%;
            }
            h2 {
                border-bottom: 1px solid #333;
                padding-bottom: 20px;
            }
        }
        .close {
            color: #333;
            font-size: 40px;
            font-weight: bold;
            position: absolute;
            top: 18px;
            right: 20px;
        }
        .close:hover,
        .close:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
        &.mediabank-detail-assets {
            .mediabank-model-container {
                top:10%;
                .mediabank-detail-assets {
                    max-height:calc( 80vh - 100px );
                    overflow:auto;
                    .mediabank-assets-list {

                        &:after {
                            content: "";
                            clear: both;
                            display: table;
                        }
                    }
                }
            }
        }
    }
    .scroll-button {
        @include breakpoint(medium) {
            display: none;
        }
        span {
            cursor: pointer;
            width: 50px;
            height: 50px;
            margin: 0 auto;
            background: #fff;
            position: absolute;
            bottom: -25px;
            left: 0;
            right: 0;
            z-index: 50;
        }
        i {
            padding: 16px 14px 12px 11px;
            display: block;
            font-size: rem-calc(21);
            position: absolute;
            animation: UpAndDown 1s infinite  alternate;
            &:before {
                color: #333;
            }
        }
        button {
            padding: 8px 14px 12px 11px;
            display: block;
            font-size: rem-calc(21);
            position: absolute;
            animation: UpAndDown 1s infinite  alternate;
            &:before {
                color: #333;
            }
        }
        @keyframes UpAndDown {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(-6px);
            }
        }
    }
    .mediabank-underneath-media {
        background-color: #fff;
        display: flex;
        padding: 40px 85px;
        @include breakpoint(small) {
            padding: 5px 15px 10px;
            flex-wrap: wrap;
        }
        .left-side .mediabank-detail-menu,
        .right-side .mediabank-detail-menu {
            height: auto;
            width: auto;
            background: none;
            position: relative;
            @include breakpoint(small) {
                padding: 10px 0;
            }
            li {
                height: 50px;
                width: 50px;
                background-color: #F5F5F5;
                padding: 14px 0px;
                margin: 0 6px;
                a {
                    position: relative;
                    top: inherit;
                    left: inherit;
                    transform: inherit;
                    color: #000;
                }
            }
        }
        .left-side {
            flex-grow: 1;
            li {
                float: left;
            }
            .metadata,
            .comments,
            .facebook,
            .pinterest,
            .twitter {
                display: none;
            }
        }
        .right-side {
            .mediabank-detail-menu {
                height: auto;
                width: auto;
                background: none;
                position: relative;
                .share {
                    background: none;
                    font-size: 17px;
                    padding: 0;
                    width: auto;
                    height: auto;
                    padding: 18px 5px;
                    font-size: rem-calc(17);
                    float: left;
                    display: block;
                    @include breakpoint(small) {
                        display: none;
                    }
                }
                li {
                    float: right;
                    height: 50px;
                    width: 50px;
                    background-color: #F5F5F5;
                    padding: 14px 0px;
                    margin: 0 6px;
                    a {
                        position: relative;
                        top: inherit;
                        left: inherit;
                        transform: inherit;
                        color: #000;
                    }
                }
                .metadata,
                .comments,
                .webshop,
                .download,
                .permalink {
                    display: none;
                }
            }
        }
    }
    .more-details-container {
        padding: 40px 100px;
        background-color: #f5f5f5;
        @include breakpoint(small) {
            padding: 15px;
        }
        .more-details-section {
            background-color: #fff;
            width: calc(100vw - 200px);
        }
        .related-images {
            margin-top: 30px;
            padding: 10px 60px;
            @include breakpoint(small) {
                width: 100%;
            }
            h3 {
                padding: 10px 0 15px;
                text-align: center;
            }
            .related-images-container {
                display: flex;
                align-items: end;
                list-style-type: none;
                padding-left: 0;
                .related-images-item:nth-child(2) {
                  padding: 0 30px;
                }
                .related-images-item {
                    img {
                      width: 100%;
                    }
                    .title,
                    .description {
                      display: block;
                    }
                }
            }
        }
        .mediabank-detail-metadata {
            display: none;
            padding: 30px;
            margin-bottom: 30px;
            display: flex;
            @include breakpoint(small) {
                width: 100%;
                flex-direction: column;
                padding: 12px;
                margin-bottom: 20px;
            }
        }
        .mediabank-metadata-list {
            flex-grow: 1;
        }
        .metadata-container {
            display: flex;
            flex-direction: column;
        }
        .metadata-heading,
        .metadata-content {
            display: inline-block;
            padding: 10px 0;
            @include breakpoint(small) {
                display: block;
            }
        }
        .metadata-content {
            width: 66%;
            @include breakpoint(small) {
                width: auto;
                padding: 0 0 10px;
            }
            .metadata-content-text {
              white-space: inherit;
            }
        }
        .metadata-heading {
            margin-right: 30px;
            text-align: right;
            width: 26%;
            vertical-align: top;
            padding: 10px 0;
            @include breakpoint(small) {
                text-align: left;
                margin-right: 0px;
                width: auto;
                padding: 8px 0 3px;
            }
        }
        .metadata-full-container {
            display: flex;
            @include breakpoint(small) {
                display: block;
            }
        }
        .mediabank-detail-map {
            display: block;
            width: 475px;
            @include breakpoint(small) {
                width: 100%;
            }
            .mediabank-map {
                height: 375px;
                @include breakpoint(small) {
                    height: 300px;
                }
            }
        }
        .mediabank-detail-comments {
            padding: 0;
            position: relative;
            &:after {
                position: absolute;
                content: '';
                width: 4px;
                height: 100%;
                background-color: #f5f5f5;
                left: 0;
                right: 0;
                top: 0;
                margin-left: auto;
                margin-right: auto;
                @include breakpoint(small) {
                    display: none;
                }
            }
            @include breakpoint(small) {
                width: 100%;
            }
            .comments-container {
                display: flex;
                @include breakpoint(small) {
                    display: block;
                }
                .intro-reactions {
                    display: none;
                }
                p, span, h4 {
                    font-size: rem-calc(17);
                    font-weight: normal;
                }
            }
            .mediabank-comment-form {
                position: relative;
                width: 100%;
                padding: 120px 60px 50px 60px;
                @include breakpoint(small) {
                    padding: 105px 15px 0 15px;
                }
                .ng-pristine {
                    padding: 0;
                }
                input[type=text],
                textarea {
                    padding: 20px 15px;
                    font-size: rem-calc(17);
                    border: 1px solid #c0c0c0;
                }
                .input-comment-email {
                    display: inline-block;
                }
                .mediabank-comment-button {
                  float: left;
                }
                .intro-comments {
                    padding: 40px 0 0 60px;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include breakpoint(small) {
                        padding: 15px;
                    }
                    h1 {
                        font-weight: normal;
                        font-size: rem-calc(17);
                    }
                    h2 {
                        display: block;
                        font-style: italic;
                        font-weight: 200;
                        font-size: rem-calc(16);
                    }
                }
            }
            .mediabank-comment-overview {
                width: 100%;
                padding: 40px 60px 50px 50px;
                @include breakpoint(small) {
                    padding: 20px 20px 5px 20px;
                }
                h3 {
                    font-size: rem-calc(17);
                    font-weight: normal;
                    margin-bottom: 44px;
                    @include breakpoint(small) {
                        margin-bottom: 25px;
                    }
                }
                .mediabank-comments-list {
                    padding: 0;
                    li {
                        border-bottom: 1px solid #c0c0c0;
                        padding-bottom: 15px;
                    }
                    h4 {
                        font-style: italic;
                    }
                    .comment-date {
                        margin-bottom: 5px;
                        font-size: rem-calc(16);
                    }
                }
            }
        }
    }

    // .related-images-container {
    //     // padding: 40px 100px;
    //     background-color: #f5f5f5;
    //     width: 100%;
    //     height: auto;
    //     h3 {
    //       text-align: center;
    //       margin: 0;
    //     }
    //     .more-details-section {
    //         background-color: #fff;
    //         display: flex;
    //     }
    // }

}
.mediabank-detail-view,
.mediabank-detail-view-v2 {
    //.no-image {
    //    img {
    //        position: absolute;
    //        left: 0;
    //        right: 0;
    //        top: calc(50% - 175px);
    //        margin-left: auto;
    //        margin-right: auto;
    //    }
    //}
    /* The switch - the box around the slider */
    .switch {
        position: absolute;
        display: inline-block;
        width: 60px;
        height: 31px;
        top: 9px;
        right: 12px;
    }
    /* Hide default HTML checkbox */
    .switch input {display:none;}
    /* The slider */
    .mapviewer-viewmode-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #777;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .mapviewer-viewmode-slider:before {
        position: absolute;
        content: "";
        height: 23px;
        width: 23px;
        left: 7px;
        bottom: 4px;
        background-color: #d50642;
        -webkit-transition: .4s;
        transition: .4s;
    }
    input:checked + .mapviewer-viewmode-slider {
        background-color: #777;
    }
    input:focus + .mapviewer-viewmode-slider {
        box-shadow: 0 0 1px #777;
    }
    input:checked + .mapviewer-viewmode-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded sliders */
    .mapviewer-viewmode-slider.round {
        border-radius: 34px;
    }
    .mapviewer-viewmode-slider.round:before {
        border-radius: 50%;
        border: 1.5px solid #fff;
    }
    .mediabank-detail-side {
        position: absolute;
        top: 80px;
        bottom: 0;
        left: 0;
        z-index: 11;
    }
    .mediabank-topviewer {
        position: absolute;
        top: 80px;
        right: 0;
        bottom: 0;
        left: 60px;
        z-index: 10000;
        text-align: center;
        &.pdf {
          overflow: auto;
        }
    }
    &.panel-open .mediabank-topviewer {
        left: 560px;
        @include breakpoint(small) {
            display: none;
        }
    }
    pic-topviewer {
        width: 100%;
        height: 100%;
        // Topviewer background in fullscreen mode
        > iframe {
            background: $mediabank-color-topviewer;
        }
    }
}
.mediabank-detail-menu {
    position: relative;
    display: block;
    width: 60px;
    height: 100%;
    background: $mediabank-color-dark;
    overflow: none;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li {
        position: relative;
        display: block;
        height: 50px;
        text-align: center;
        &.active {
            background: #fff;
            a {
                color: $mediabank-color-contrast;
            }
        }
        &:last-child {
            margin-bottom: 10px;
        }
    }
}
.mediabank-detail-panel-button {
    @include center();
    display: block;
    color: #fff;
    cursor:pointer;
    i {
        font-size: rem-calc(24);
        // Some icons need a bit of tweaking to the size
        &.pic-icon-permalink {
            font-size: 28px;
        }
        &.pic-icon-webshop {
            font-size: 30px;
        }
        &.pic-icon-download {
            font-size: 22px;
        }
        &.pic-icon-assets {
            font-size: 20px;
        }
        .pic-icon-comment-count {
            clip: auto;
            right:-10px;
            top:-10px;
            z-index:9000;
            padding:3px 6px;
            border-radius:11px;
            display:block;
            height:22px;
            width:auto;
            min-width:22px;
            color: #fff;
            font-style:normal;
            font-weight:500;
            background-color:$mediabank-color-contrast;
            font-size: 13px;
        }
    }
    span {
        @include visuallyhidden();
    }
    &:hover {
        color: $mediabank-color-contrast;
    }
}
.mediabank-detail-panels {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60px;
    > section {
        position: relative;
        overflow-y: auto;
        padding: 20px 30px;
        width: 500px;
        height: 100%;
        background: #fff;
        border-right: 1px solid #ddd;
        @include breakpoint(small) {
            width: 100vw;
            padding: 20px 80px 20px 20px;
        }
    }
    h2 {
        color: $mediabank-color-text;
        font-weight: normal;
        font-size: rem-calc(24);
    }
    h3 {
        color: $mediabank-color-text;
        font-weight: 700;
        font-size: rem-calc(16);
    }
}
.mediabank-detail-panel-close {
    @include mediabank-close-button();
    position: absolute;
    top: 20px;
    right: 20px;
    @include breakpoint(small) {
        right: 80px;
    }
}
// Metadata panel
.mediabank-detail-metadata {
    li {
        margin-bottom: rem-calc(10);
    }
}
// Comments
.mediabank-comment-form {
    @include clearfix();
    position: relative;
    input[type="text"],
    textarea {
        position: relative;
        margin-bottom: 10px;
        padding: 5px;
        width: 100%;
        height: 30px;
        margin-top: 3px;
        border: 2px solid $mediabank-color-primary;
        font-family: $mediabank-font;
        font-size: rem-calc(14);
    }
    textarea {
        height: 120px;
    }
    .input-comment-email {
        display: inline-block;
    }
    .mediabank-comment-button {
        @include mediabank-button();
        float: right;
        @include breakpoint(mobile) {
            width: 100%;
        }
    }
}
.mediabank-comment-errors {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        margin-bottom: rem-calc(6);
        padding: rem-calc(4 5);
        border-radius: 3px;
        background: rgba($mediabank-color-error, 0.15);
        color: $mediabank-color-error;
        border: 1px solid #c92236;
        text-align: center;
        line-height: rem-calc(20);
    }
}
.comment-success {
    background: $mediabank-color-success;
    padding: 5px;
    .pic-icon-close {
        float: right;
        cursor: pointer;
    }
}
.mediabank-comments-list {
    @include ul-reset();
    padding: 0 10px;
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    li {
        margin-bottom: 20px;
    }
    h4 {
        margin: 0;
        color: $mediabank-color-text;
    }
    .comment-date {
        display: block;
        color: $mediabank-color-gray-mid;
    }
    .comment-content {
        white-space: pre-wrap;
    }
}
// Permalink
.mediabank-detail-permalink, .mediabank-detail-iiif {
    .mediabank-permalink-input, .iiif-manifest-link-input {
        margin: 0;
        display: block;
        padding: 5px;
        width: 90%;
        height: 40px;
        border: 2px solid $mediabank-color-primary;
        font-family: $mediabank-font;
        font-size: rem-calc(14);
        float: left;
    }
    .mediabank-permalink-container, .iiif-manifest-link-container {
        position: relative;
        margin: 10px 0;
    }
    .label-permalink, .label-iiif-manifest-link {
        margin-bottom: 3px;
        display: block;
    }
    .mediabank-permalink-copy, .iiif-manifest-link-copy {
        @include mediabank-button($mediabank-color-contrast);
        position: relative;
        right: 0;
        top: 0;
        display: block;
        margin: 0;
        padding: 0;
        width: 10%;
        height: 40px;
        span {
            @include visuallyhidden();
        }
        i {
            font-size: 18px;
        }
    }
    .mediabank-permalink-message, .iiif-manifest-link-message {
        position: absolute;
        left: 50%;
        top: calc(100% + 5px);
        max-width: 100%;
        padding: 10px;
        transform: translateX(-50%);
        background: $mediabank-color-dark;
        text-align: center;
        color: #fff;
        &::before {
            @include triangle('up', 20px, $mediabank-color-dark);
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            margin-left: -20px;
            top: -30px;
        }
        p {
            margin: 0;
        }
    }
}
#pic-mediabank-add-comment {
	@include mediabank-button();
}
// Webexposition button
.mediabank-detail-view {
    .mediabank-webexposition-button {
        @include mediabank-button($mediabank-color-primary);
        display: block;
        position: absolute;
        z-index: 10001;
        bottom: 65px;;
        right: 16px;
        padding-right: 40px;
        @include breakpoint(small) {
            bottom: 10px;
        }
        // icon
        i {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
#mediabank-detail-assets-bottom {
    min-height: 65px;
}
