@mixin ul-reset() {
    margin: 0;
    padding: 0;
    list-style: none;
}

/*
 * Micro clearfix hack
 * http://nicolasgallagher.com/micro-clearfix-hack/
 */
@mixin clearfix() {
    zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear:both;
    }
}

/*
 * This hide-text is supposed to have better performance then the -9999px technique
 * http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
 */
@mixin hide-text() {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

// Only visible for screen readers
@mixin visuallyhidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Add percentage of white to a color
@function tint($color, $percent) {
    @return mix(white, $color, $percent);
}

// Add percentage of black to a color
@function shade($color, $percent) {
    @return mix(black, $color, $percent);
}

// General styling for buttons used in mediabank
@mixin mediabank-button($background: $mediabank-color-contrast) {
    display: inline-block;
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
    font-weight: normal;
    font-size: rem-calc(16);
    line-height: 20px;
    margin: 0;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    border-radius: $mediabank-border-radius;
    background: $background;
    transition: background 0.2s ease-out;

    &:hover {
        background: shade($background, 10%);
    }

    // Fixes inconsistent button height in FF
    &::-moz-focus-inner {
        border: 0;
    }
}

// Media query breakpoints
@mixin breakpoint($point) {
    @if $point == large {
        @media only screen and (max-width: 1280px) {
            @content;
        }
    }

    @else if $point == medium {
        @media only screen and (max-width: 1024px) {
            @content;
        }
    }

    @else if $point == small {
        @media only screen and (max-width: 768px)  {
            @content;
        }
    }

    @else if $point == mobile {
        @media only screen and (max-width: 480px)  {
            @content;
        }
    }
}

// Center element
@mixin center() {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

// Close button used in panel and focus container
@mixin mediabank-close-button() {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $mediabank-color-primary;
    color: #fff;
    font-size: rem-calc(17);

    i {
        @include center();
    }

    span {
        @include visuallyhidden();
    }

    &:hover {
        background: shade($mediabank-color-primary, 10%);
        color: #fff;
    }
}

// Creates CSS only triangle
@mixin triangle($dir, $size, $color) {
    border: $size transparent solid;

    @if ($dir == "top" or $dir == "up") {
        border-bottom-color: $color;
    }
    @else if ($dir == "right" or $dir == "after") {
        border-left-color: $color;
    }
    @else if ($dir == "bottom" or $dir == "down") {
        border-top-color: $color;
    }
    @else if ($dir == "left" or $dir == "before") {
        border-right-color: $color;
    }
}

@mixin button-reset() {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  border: none;
}
