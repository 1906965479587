.mediabank-filters {
    margin-bottom: 10px;

    .mediabank-mode-detail & {
        display: none;
    }
}

.filter-indicator {
    display: none;
}

.mediabank-filter-list {
    @include clearfix();
    @include ul-reset();
    padding: 10px 20px 0;

    > li {
        display: inline-block;
        margin-right: 12px;
        margin-bottom: 16px;

        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }

        &.active {
            color: $mediabank-color-contrast;
        }
    }

    .mediabank-filter {
        position: relative;
        font-size: rem-calc(16);
        cursor: pointer;
        white-space: nowrap;

        &-icon {
            position: relative;
            top: -2px;
            display: inline-block;
            margin: 0;
            vertical-align: middle;
            transform: scale(0.6) translateX(-4px);
        }

        &:hover {
            color: $mediabank-color-contrast;
        }
    }
}

.mediabank-filter-search {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;

    input[type="text"] {
        display: block;
        padding: 5px 10px;
        width: 260px;
        height: 36px;
        border: 2px solid #fff;
        border-right:0;
        background: transparent;
        font-size: rem-calc(16);
        color: $mediabank-color-text-light;
    }

    .filter-search-icon {
        position: absolute;
        right: -34px;
        top: 0px;
        border:2px solid #fff;
        border-left:none;
        z-index:1;
        padding:6px;
        width:34px;
        height:36px;
        color: #fff;
        display:block;
        //background-color:#fff;
    }

    @include breakpoint(small) {
        width: 100%;
        padding-right: 100px;
        display: block;

        input[type="text"] {
            width: 100%;
        }

        .filter-search-icon {
            right: 106px;
        }
    }
}

// Dropdown containing the filters
.mediabank-filter-dropdown {
    position: relative;
    margin-bottom: 20px;
    min-height: 150px;
    background: $mediabank-color-gray-dark;

    .mediabank-dropdown-content {
        padding: 20px 80px 30px;

        @include breakpoint(small) {
            padding: 20px;
        }
    }

    .mediabank-filter-pagination {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .mediabank-page-prev,
    .mediabank-page-next {
        position: absolute;
        top: 40px;
        margin-top: -18px;
        display: block;
        width: 36px;
        height: 36px;
        line-height: 36px;
        border-radius: 50%;
        background: $mediabank-color-gray-mid;
        font-size: rem-calc(18);
        text-align: center;
        color: #fff;
        cursor: pointer;

        &.disabled {
            display: none;
            cursor: default;
        }

        @include breakpoint(small) {
            top: 40px;
        }
    }

    .mediabank-page-prev {
        left: 20px;

        @include breakpoint(small) {
            left: auto;
            right: 66px;
        }

        i {
            margin-left: -2px;
        }
    }

   .mediabank-page-next {
       right: 20px;

       i {
           margin-right: -2px;
       }
   }

   .mediabank-loader {
       position: absolute;
       top: 20px;
       left: 50%;
       -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
               transform: translateX(-50%);
       display: none;
   }

   &.loading {
       .mediabank-filter-links-list {
           opacity: 0;
       }

       .mediabank-loader {
           display: block;
       }
   }
}

.mediabank-filter-links-list {
    @include ul-reset();
    @include clearfix();
    transition: opacity 0.2s ease-out;
    position: relative;
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 30px;
       -moz-column-gap: 30px;
            column-gap: 30px;
    opacity: 1;

    @include breakpoint(medium) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    @include breakpoint(mobile) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }

    li {
        display: block;
        padding-right: 20px;
        margin-bottom: 2px;
        -webkit-column-break-inside: avoid;
           -moz-column-break-inside: avoid;
                column-break-inside: avoid;
    }

    a {
        color: #fff;
        text-decoration: none;
        font-size: rem-calc(14);
        line-height: rem-calc(20);

        &:hover {
            color: $mediabank-color-primary;
        }
    }
}

// Active filters
.mediabank-filters-active {
    padding: 0 10px;

    ul {
        @include ul-reset();

        li {
            display: inline-block;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .filter-active {
        display: inline-block;
        margin-bottom: 10px;
        padding: 5px 10px 5px 5px;
        border-radius: 3px;
        background: $mediabank-color-contrast;
        line-height: rem-calc(20);
        color: #fff;
        font-size: rem-calc(14);
        font-weight: bold;
        text-decoration: none;

        .filter-label {
            display: inline-block;
            margin-right: 5px;
            font-weight: normal;
        }

        .pic-icon-close {
            // font-size: rem-calc(10);
        }
    }
}
