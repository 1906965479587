@media print {
    .pic-mediabank {
        *,
        *:before,
        *:after {
            background: transparent !important;
            color: #000 !important; /* Black prints faster:
                                    http://www.sanbeiji.com/archives/953 */
            box-shadow: none !important;
            text-shadow: none !important;
            font-size: 12px !important;
            line-height: 18px !important;
        }

        a,
        a:visited {
            text-decoration: underline;
        }

        a[href^="#"]:after,
        a[href^="javascript:"]:after {
            content: "";
        }

        pre,
        blockquote {
            border: 1px solid #999;
            page-break-inside: avoid;
        }

        thead {
            display: table-header-group;
        }

        tr,
        img {
            page-break-inside: avoid;
        }

        img {
            max-width: 100% !important;
        }

        p,
        h2,
        h3 {
            orphans: 3;
            widows: 3;
        }

        h2,
        h3 {
            page-break-after: avoid;
        }
    }

    .mediabank-search,
    .mediabank-result-options,
    .mediabank-gallery-tooltip,
    .mediabank-detail-map,
    .mediabank-comment-form,
    .mediabank-detail-panel-close,
    .mediabank-topviewer,
    .mediabank-filters {
        display: none;
    }

    .mediabank-result-header {
        .mediabank-results-total {
            margin-bottom: 10px;
            font-size: 16px !important;
        }
    }

    .mediabank-result-header,
    .mediabank-gallery-list,
    .mediabank-detail-metadata,
    .mediabank-gallery {
        margin: 0;
        padding: 0;
    }

    .mediabank-detail-menu {
        display: none;
    }

    .mediabank-detail-side {
        position: relative;
        top: auto;
    }

    .mediabank-detail-panels {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        z-index: 100;
    }

    .mediabank-detail-view .mediabank-topviewer {
        position: relative;
        z-index: 99;
    }

    .mediabank-container.mediabank-mode-detail {
        position: relative !important;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
    }

    .mediabank-detail-print {
        display: block !important;
        margin-bottom: 10px;
    }

    .mediabank-detail-view .mediabank-detail-side {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
    }

    .mediabank-detail-panels>section {
        width: auto;
        height: auto;
        padding: 0;
    }
}
