.mediabank-result-table {
    margin-top: rem-calc(30);

    .mediabank-gallery-focused {
        position: relative;
        width: 100% !important;
    }

    .mediabank-result-list {
        margin: rem-calc(20 0);
        width: 100%;
        border: none;
        background: #fff;
        table-layout: auto;
        border-spacing: 0;

        thead {
            background: $mediabank-color-light;

            tr {
                th,
                td {
                    padding: rem-calc(8 10 8 20);
                    color: $mediabank-color-text;
                    font-weight: 400;
                    text-align: left;
                    font-size: rem-calc(16);
                }
            }
        }

        tr {
            th,
            td {
                padding: rem-calc(5 10 5 20);
                color: $mediabank-color-text;
                font-size: rem-calc(14);
            }

            &.even,
            &.alt,
            &:nth-of-type(even) {
                background: tint($mediabank-color-light, 65%);
            }
            &.mediabank-result-row {
              cursor: pointer;

              &:hover, &:visited, &.focused {
                background-color: $mediabank-color-light;
              }
            }
        }

        thead tr th,
        tfoot tr th,
        tfoot tr td,
        tbody tr th,
        tbody tr td,
        tr td {
            display: table-cell;
            line-height: rem-calc(20);
        }
    }
}
