.fullpage {
    .mediabank-mapview-button {
        display: none;
    }
}

.mediabank-detail-view-v2 .mapviewer slider {
    margin: 24px 0 16px 122px;
}

.mapviewer {
  height: 100%;
	position: relative;
    .angular-leaflet-map {
      z-index:99;
    }

    slider {
        width: 35%;
        height: 10px;
        margin: 24px 0 16px 45px;
        @include breakpoint(medium) {
            width: 31%;
        }
        .handle {
            background-color: transparent;
            width: 0;
            &:after {
                background-color: #ccc;
                width: 7px;
                height: 26px;
                top: -5px;
                left: 0;
            }
        }
        .bar {
            border-radius: 0;
            height: 5px;
        }
    }

    &-opacity-slider {
        position: absolute;
        z-index: 100;
        right: 180px;
        bottom: 20px;
        color: rgb(204, 204, 204);
        width: 300px;
        height: 50px;
        margin: 0px;
        padding: 0px;
        background: rgba(20, 20, 20, 0.8);
        outline: none;
        @include breakpoint(medium) {
            right: 10px;
            bottom: 75px;
            width: 200px;
        }
    }

    &-toggle {
        position: absolute;
        z-index: 100;
        right: 10px;
        bottom: 20px;
        color: rgb(204, 204, 204);
        width: 165px;
        height: 50px;
        margin: 0px;
        padding: 0px;
        background: rgba(20, 20, 20, 0.8);
        outline: none;
    }

    .leaflet-control-layers {
        display: none;
    }

    .leaflet-left {
        right: 10px; // Override to move leaflet to right side
        top: 10px;
        left: inherit;
        background: rgba(20,20,20,0.7);
        .leaflet-control {
            margin: 0;
        }

        .leaflet-bar {
			box-shadow: none;

			a {
				width: 44px;
				height: 44px;
			}

            a:last-child,
            a:first-child {
                border: none;
                border-radius: 0;
            }

        }
    }

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    .leaflet-control-fullscreen-button {
        text-indent: -1000em;
        position: relative;
        background-repeat: no-repeat !important;
        background-position: 10px !important;
        transition: background 0.25s ease-out;
        width: 44px;
        height: 44px;
        &:hover {
            background-color: rgba(20,20,20,0.8);
            transition: background 0.2s ease-out;
            width: 44px;
            height: 44px;
        }
    }

    .leaflet-control-zoom-in {
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+em9vbWluPC90aXRsZT48cGF0aCBkPSJNMTUuOTY2IDE4LjAyNkE5Ljk1NSA5Ljk1NSAwIDAgMSAxMCAyMEM0LjQ3NyAyMCAwIDE1LjUyMyAwIDEwUzQuNDc3IDAgMTAgMHMxMCA0LjQ3NyAxMCAxMGMwIDIuMjM2LS43MzQgNC4zLTEuOTc0IDUuOTY2bDUuNDc0IDUuNDczLTIuMDYgMi4wNjEtNS40NzQtNS40NzR6TTExIDlWNkg5djNINnYyaDN2M2gydi0zaDNWOWgtM3ptLTEgOWE4IDggMCAxIDAgMC0xNiA4IDggMCAwIDAgMCAxNnoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
    }

    .leaflet-control-zoom-out {
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ0LjEgKDQxNDU1KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTE4LjAyNjM3MjYsMTUuOTY1NzEyNCBMMjMuNSwyMS40MzkzMzk4IEwyMS40MzkzMzk4LDIzLjUgTDE1Ljk2NTcxMjQsMTguMDI2MzcyNiBDMTQuMzAwMzA0NywxOS4yNjYyMzI5IDEyLjIzNTg0MjYsMjAgMTAsMjAgQzQuNDc3MTUyNSwyMCAwLDE1LjUyMjg0NzUgMCwxMCBDMCw0LjQ3NzE1MjUgNC40NzcxNTI1LDAgMTAsMCBDMTUuNTIyODQ3NSwwIDIwLDQuNDc3MTUyNSAyMCwxMCBDMjAsMTIuMjM1ODQyNiAxOS4yNjYyMzI5LDE0LjMwMDMwNDcgMTguMDI2MzcyNiwxNS45NjU3MTI0IFogTTEwLDE4IEMxNC40MTgyNzgsMTggMTgsMTQuNDE4Mjc4IDE4LDEwIEMxOCw1LjU4MTcyMiAxNC40MTgyNzgsMiAxMCwyIEM1LjU4MTcyMiwyIDIsNS41ODE3MjIgMiwxMCBDMiwxNC40MTgyNzggNS41ODE3MjIsMTggMTAsMTggWiBNNiw5IEwxNCw5IEwxNCwxMSBMNiwxMSBMNiw5IFoiIGlkPSJ6b29tb3V0IiBmaWxsPSIjRkZGRkZGIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==");
    }

    .leaflet-control-fullscreen-button {
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNOS4xMTcsMTcuMzhsMi44MDktMi44MWwtMi4yNjgtMi4yNjlsLTIuODEsMi44MDlMNC41OCwxMi44NDN2Ni44MDVoNi44MDVMOS4xMTcsMTcuMzh6IE0xNC43OTcsMTEuNjk5DQoJCQlsMi44MTEtMi44MDlsMi4yNjksMi4yNjhWNC4zNTJIMTMuMDdsMi4yNjksMi4yNjhsLTIuODEsMi44MUwxNC43OTcsMTEuNjk5eiBNMCwwdjI0aDI0VjBIMHogTTIxLjgwNywyMS44MDdIMi4xOTNWMi4xOTNoMTkuNjE0DQoJCQlWMjEuODA3eiIvPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K");
    }

    .leaflet-touch .leaflet-control-fullscreen a {
        background-position: 2px 2px;
    }
    .leaflet-fullscreen-on .leaflet-control-fullscreen a {
        background-position:0 -26px;
    }
    .leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
        background-position: 2px -24px;
    }

    /* Do not combine these two rules; IE will break. */
    .leaflet-container:-webkit-full-screen {
        width:100%!important;
        height:100%!important;
    }
    .leaflet-container.leaflet-fullscreen-on {
        width:100%!important;
        height:100%!important;
    }

    .leaflet-pseudo-fullscreen {
        position:fixed!important;
        width:100%!important;
        height:100%!important;
        top:0!important;
        left:0!important;
        z-index:99999;
    }

}

// Container box around the switch
.mediabank-mapview-button {
    position: absolute;
    z-index: 100;
    right: 10px;
    bottom: 20px;
    margin: 0;
    padding: 0;
    color: #ccc;
    width: 165px;
    height: 50px;
    background: rgba(20,20,20,0.8);
    outline: none;
}

// Text for the map viewer
.toggle-text {
    position: absolute;
    left: 15px;
    top: 17px;
}

.opacity-text {
    position: absolute;
    left: 13px;
    top: 17px;
}

.opacity-percentage {
    position: absolute;
    right: 13px;
    top: 17px;
}
